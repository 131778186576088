import axios from "@/plugins/axios";
import {Commit, Module, MutationPayload} from "vuex";
import CategoryInterface from "@/interfaces/CategoryInterface";
import ProductsResponseInterface from "@/interfaces/ProductsResponseInterface";
import store from "@/store/index"
import OrderProductInterface from "@/interfaces/OrderProductInterface";
import {AxiosError} from 'axios';

interface StateInterface {
    categories: CategoryInterface[],
    categories_ready: boolean,
    baseUrl: string
}

export default {
    state: {
        categories: [],
        categories_ready: false,
        baseUrl: "https://mafiola.net/storage"
    } as StateInterface,
    actions: {
        getCategories: async ({ commit }: {commit: Commit}) => {
            try {
                const response = await axios.get(`/products`) as ProductsResponseInterface;
                commit("setCategories", response)
            } catch (e) {
                if (e instanceof AxiosError) {
                    commit("addError", e?.response?.data?.message || "Error")
                }
                console.error(e)
                commit("setCategories", {data: []})
            }
        }
    },
    mutations: {
        setCategories: (state: StateInterface, data: ProductsResponseInterface) => {
            state.categories = data.data
            state.categories_ready = true
        }
    },
    getters: {
        categories_ready: (state: StateInterface) => {
            return state.categories_ready
        },
        categories: (state: StateInterface) => {
            return state.categories
        },
        currentCategory: (state: StateInterface) => (categoryId: string) => {
            return store.getters.categories.find((item: CategoryInterface) => {
                if (!categoryId) return true;
                return item.id == categoryId
            })
        },
        productById: (state: StateInterface) => (productId: string) => {
            let product = null
            state.categories.find((category) => {
                product = category.products.find((product) => {
                      return product.id == productId
                })
                return product
            })
            return product
        },
        productCategoryById: (state: StateInterface) => (productId: string) => {
            return state.categories.find((category) => {
                return category.products.find((product) => {
                    return product.id == productId
                })
            })
        },
        productQuantity: (state: StateInterface) => (productId: string) => {
            const order = store.getters.selectedOrder
            if (!order) return 0;
            const product = order.products.find((product: OrderProductInterface) => {return product.product_id == productId}) || {quantity:0}
            return product.quantity
        },
        baseUrl: (state: StateInterface) => {
            return state.baseUrl
        },
    }
}