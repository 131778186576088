<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" xl="2">
        <v-sheet rounded="lg" class="mt-3">
          <v-list color="transparent">
<!--            <v-list-item>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title>-->
<!--                  Today-->
<!--                </v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->

<!--            <v-divider class="my-2"></v-divider>-->

            <v-list-item v-if="!$store.getters.categories_ready">
              <v-progress-circular
                  indeterminate
                  color="primary"
                  class="mx-auto"
              ></v-progress-circular>
            </v-list-item>
            <v-list-item
                v-else
                v-for="(n, i) in $store.getters.categories"
                :key="i"
                link
                :to="`/catalog/${n.id}`"
                :class="[{'selected': $route.params.categoryId == n.id}]"
            >
              <v-list-item-avatar>
                <v-img :src="`${$store.getters.baseUrl}/${n.image}`"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ n.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
        <catalog-cart v-if="$store.getters.isAuthorized"></catalog-cart>
      </v-col>

      <v-col cols="12" sm="6" md="8" lg="8" xl="10">
        <catalog-items></catalog-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CatalogCart from "@/components/CatalogCart"
import CatalogItems from "@/components/CatalogItems"

export default {
  components: {
    "catalog-cart": CatalogCart,
    "catalog-items": CatalogItems,
  },
}
</script>

<style scoped>
.v-list-item {
  transition: .5s;
}
.selected.v-list-item {
  border-left: 5px solid #4caf50;
}
</style>