import axios from "axios";
import store from "@/store/index"

const instance = axios.create({
    baseURL: `${process.env.VUE_APP_URL}`,
    headers: {
        'Content-Type': 'application/json',
        // "authorization": "Bearer 1|0YcEljGuhUtEldnakyRwYaLyRB8cQTV5qwfldt7U"
    },
});

// Add a request interceptor
instance.interceptors.request.use((config) => {
    // Do something before the request is sent
    if (config.url != "/login") {
        config.headers.authorization = `Bearer ${store.getters.token}`
    }
    return config;
}, error => {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use((response) => {
    // Do something with response data
    return response;
}, error => {
    // Do something with response error
    return Promise.reject(error);
});

export default instance