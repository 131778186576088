<template>
  <v-container>
    <h1>Pomoc: (v{{ $store.getters.packageVersion }})</h1>
    <v-expansion-panels>
      <v-expansion-panel
          v-for="(a,q) in qas"
          :key="q"
      >
        <v-expansion-panel-header>
          {{ q }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ a }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    qas: {
      "Nie jestem zweryfikowany. Dlaczego?": "Zanim będziemy mogli przyjąć Twoje zamówienia, musimy upewnić się, że adres dostawy wybranych przez Ciebie dań jest prawidłowy.  Zweryfikujemy Twój adres i jeśli nie będzie problemów, otrzymasz potwierdzenie bez dalszych działań",
      "Dlaczego musiałem podać swój numer telefonu?": "Twój numer telefonu jest potrzebny do rozwiązywania problemów z zamówieniami i płatnościami.\n" +
          "Nie będziemy udostępniać ich stronom trzecim ani reklamować naszych usług",
      "Jak długo trwa weryfikacja?": "Staramy się zweryfikować naszych użytkowników w dniu utworzenia konta",
      "Jak coś zamówić?": "Upewnij się, że masz trochę pieniędzy na koncie.\n" +
          "Przejdź do katalogu.\n" +
          "Wybierz dzień dostawy i wybierz danie, które chcesz zjeść.\n" +
          "Na koniec potwierdź zamówienie.",
      "Jak działa zamawianie?": "Zamówienia przyjmujemy do godziny 17:00 na dzień przed dostawą. Do momentu zablokowania zamówienia możesz je zmienić",
      "Kiedy zamówienie zostanie dostarczone?": "W tej chwili czas dostawy ustalamy telefonicznie. Ta funkcja zostanie wkrótce dodana tutaj",
      "Chcę zapłacić gotówką": "Konto możesz doładować przy odbiorze zamówienia lub odwiedzając nas bezpośrednio (Garbary 125)",
      "Mojego problemu nie ma na tej liście!": "Zadzwoń do nas (+48505389621)",
      "Mam kilka pomysłów lub pytań, które nie wymagają natychmiastowej uwagi": "Mail us (nm.palanica@gmail.com)"
    }
  }),
}
</script>