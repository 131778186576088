import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import routes from './routes'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const routeFound = ['Login', 'Register', 'Catalog', 'Category', 'Help', 'registerToken'].find((item) => {return item == to.name})
  if (
      !store.getters.isAuthorized &&
      !routeFound
  ) {
    console.log("Redirect ro login")
    next({ name: 'Login' })
  }

  next()
})

export default router
