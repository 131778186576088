import axios from "@/plugins/axios";
import {Commit, GetterTree, Module, MutationPayload} from "vuex";

import store from "@/store/index"
import {AxiosError} from "axios";
import PointsInterface from "@/interfaces/PointsInterface";
import PointsResponseInterface from "@/interfaces/PointsResponseInterface";
import OrdersResponseInterface from "@/interfaces/OrdersResponseInterface";

interface StateInterface {
    activated_points_list: PointsInterface[],
    points_loaded: boolean,
}
export default {
    state: {
        activated_points_list: [],
        points_loaded: false,
    } as StateInterface,
    actions: {
        getPoints: async ({ commit }: {commit: Commit}) => {
            commit("setPoints", {data: []})
            commit("setPointsLoaded", false)
            try {
                const response = await axios.get(`/token-history`) as PointsResponseInterface;
                commit("setPoints", response.data)
            } catch (e) {
                if (e instanceof AxiosError) {
                    commit("addError", e?.response?.data?.message || "Error")
                }
            }
        },
    },
    mutations: {
        setPointsLoaded: (state: StateInterface, value: boolean) => {
            state.points_loaded = value
        },
        setPoints: (state: StateInterface, data: PointsResponseInterface) => {
            state.activated_points_list = data.data
            state.points_loaded = true
        },
    },
    getters: {
        activated_points_list: (state: StateInterface) => {
            return state.activated_points_list
        },
        points_loaded: (state: StateInterface) => {
            return state.points_loaded
        },
    }
}