<template>
  <v-container fluid>
    <v-progress-circular
        v-if="!$store.getters.categories_ready"
        indeterminate
        color="primary"
        class="mx-auto d-block"
    ></v-progress-circular>
    <div v-if="$store.getters.categories_ready && !$store.getters.currentCategory($route.params.categoryId).products.length" class="text-center">Empty!</div>
    <v-sheet
        v-if="$route.params.categoryId && !$store.getters.currentCategory($route.params.categoryId).name.match(/Pizza/)"
        rounded="lg"
        class="mb-3"
    >
      <v-alert
          border="left"
          color="orange"
          type="warning"
          text
      >
        This products are only available on pre-calls
      </v-alert>
    </v-sheet>
    <v-row dense v-if="$store.getters.categories_ready">
      <v-col
          v-for="product in $store.getters.currentCategory($route.params.categoryId).products"
          :key="`${product.id}`"
          cols="12"
          sm="12"
          md="6"
          lg="3"
          :id="`product-${product.id}`"
      >
        <v-card>
          <v-img
              :src="`${$store.getters.baseUrl}/${product.image}`"
              class="white--text align-end product-image"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
          >
            <div class="product-content">
              <v-app-bar
                  flat
                  dense
                  color="rgba(0, 0, 0, 0)"
              >
                <v-spacer></v-spacer>
                <v-chip class="mr-1 success" v-if="!$store.getters.isAuthorized || $store.getters.selectedOrder?.mode == 'PLN'">{{ product.price }} PLN</v-chip>
                <v-chip class="mr-1 success" v-if="$store.getters.isAuthorized && $store.getters.selectedOrder?.mode == 'points'">{{ product.price }} Baly</v-chip>
              </v-app-bar>
              <div class="product-content-text">
                <v-card-title class="pt-0 text-decoration-underline">{{ product.product_description?.title }}</v-card-title>
                <v-card-subtitle class="scroll-hidden">
                  <div>{{ product.product_description?.description }}</div>
                </v-card-subtitle>
              </div>
            </div>
          </v-img>

          <v-card-actions
              :class="[{'success lighten-1': $store.getters.productQuantity(product.id) > 0}]"
              class="transition-ease-in-out flex-wrap justify-end"
          >
            <v-icon v-if="$store.getters.productQuantity(product.id)">mdi-cart</v-icon>
            <v-spacer></v-spacer>
            <div v-if="$store.getters.isAuthorized">
              <v-btn
                  icon
                  :class="[{'error': $store.getters.productQuantity(product.id) > 0}]"
                  @click="minusProduct(product.id)"
                  :disabled="$store.getters.productQuantity(product.id) == 0"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-chip class="mx-1">{{ $store.getters.productQuantity(product.id) }}</v-chip>
              <!--                      <span class="mx-1">1</span>-->
              <v-btn icon class="success" @click="addProduct(product.id)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
            <v-btn v-else icon class="success" @click="beg">
              <v-icon>mdi-cart</v-icon>
            </v-btn>

            <!--                      <v-btn icon class="ml-5 success">-->
            <!--                        <v-icon>mdi-cart-outline</v-icon>-->
            <!--                      </v-btn>-->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";

export default {
  data: () => ({
    shift: 0,
  }),
  methods: {
    beg() {
      this.$store.commit('addError', 'Nie ma wystarczającej informacji, aby przetworzyć zamówienie. Proszę zarejestruj się')
      this.$router.push("/register");
    },
    addProduct(productId) {
      let product = this.$store.getters.selectedOrderProduct(productId);
      // this.shift++;
      if (!product) {
        store.dispatch("addProductToOrder", {productId: productId})
        return;
      }
      this.$store.commit("increaseProductQuantity", {productId: productId})
    },
    minusProduct(productId) {
      this.$store.commit("decreaseProductQuantity", {productId: productId})
    }
  },
  async mounted() {
    if (!this.$store.getters.orders_loaded && this.$store.getters.isAuthorized)
      this.$store.dispatch("getOrders");
    if (!this.$store.getters.categories_ready)
      this.$store.dispatch("getCategories");
  }
}
</script>

<style lang="scss">
.product-image {
  & > .v-responsive__content {
    width: 1200px;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
  }
}
.product-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  .product-content-text {
    position: absolute;
    height: 100%;
    top: calc(100% - 52px);
    transition: .5s;
    padding-top: 15px;
    overflow: auto;
    left: 0;
    right: -17px;
    padding-right: 17px;
    &:hover {
      top: 0;
      background: #000000cc;
    }
  }
}
.scroll-hidden {
  overflow: hidden;
  position: relative;
  & > div {
    //overflow: auto;
    //position: absolute;
    //right: 0px;
  }
}
</style>