<template>
  <v-container class="h-100">
    <div class="text-center my-5">
      <h1>Witamy serdecznie w pizzerii Mafioła!</h1>
      <h3>Mamy fioła na punkcie dobrego jedzenia</h3>
    </div>
    <v-row>
      <v-col cols="12" offset="0" sm="8" offset-sm="2" md="6" offset-md="3">
        <v-expansion-panels class="mb-5">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Dlaczego warto się zarejestrować? 🤔
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Stworzyliśmy tę stronę, aby uprościć zamówienia w celu zapewnienia wygodnego życia ze smakiem.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Zdobyto kilka punktów, ale nie chcę się rejestrować ¯\_(ツ)_/¯
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Bez problemu. Zebrane QR kody przynieś do pizzerii i zabierz
              wybraną pizzę
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-alert v-if="hasPointsActionScheduled" class="mb-3" icon="mdi-party-popper" type="success">
          Gratulacje!!! Aby je zakupić należy zarejestrować się na stronie
        </v-alert>
        <v-card>
          <v-card-title>
            <div class="d-flex justify-space-between" style="width: 100%">
              <span>Rejestr</span>
              <!--              <v-spacer></v-spacer>-->
              <v-btn text color="primary" to="/login" class="login">
                Zaloguj sie
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-text-field
                label="Imię"
                outlined
                hide-details
                v-model="name"
                class="mb-5 name"
                autocomplete="given-name"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
                label="Numer telefonu"
                outlined
                v-model="phone"
                hint="Bez żadnych reklam. Autoryzacja i przetwarzanie zamówień"
                persistent-hint
                class="my-5 phone-number"
                autocomplete="tel"
            ></v-text-field>
            <v-text-field
                class="address"
                label="Adres"
                outlined
                v-model="address"
                hint="Wyłącznie na dowóz"
                persistent-hint
                autocomplete="street-address"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
                label="Hasło"
                outlined
                hide-details
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                v-on:keyup.enter="register"
                class="mt-5 password"
                autocomplete="new-password"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <span class="secondary--text">+48505389621 (Pomoc)</span>
            <v-spacer></v-spacer>
            <v-btn class="register" color="primary" @click="register" :loading="loading">Rejestr</v-btn>
          </v-card-actions>
        </v-card>
        <div class="mt-5 d-flex justify-center">
          <v-btn
              color="success"
              class="font-weight-bold"
              to="/catalog"
          >Gość</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    name: "",
    address: "",
    phone: "",
    password: "",
    showPassword: false,
    loading: false,
  }),
  computed: {
    hasPointsActionScheduled() {
      return !!this.$store.getters.postAuthActions.filter((action) => {return action.type == "applyToken"}).length;
    }
  },
  methods: {
    async register() {
      this.loading = true
      let result = await this.$store.dispatch("register", {
        name: this.name,
        address: this.address,
        phone: this.phone,
        password: this.password
      })
      if (!result) {
        this.loading = false
        return;
      }
      result = await this.$store.dispatch("loadProfile");
      if (!result) {
        this.loading = false
        return;
      }
      this.loading = false
    }
  }
}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0 0 0px 1000px #1e1e1e inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>