<template>
  <div>
    <v-bottom-navigation
        app
        fixed
        hide-on-scroll
        v-if="$store.getters.selectedOrder"
        v-show="($store.getters.selectedOrder && $store.getters.selectedOrder.products.length) || !orderUnConfirmable"
    >
      <template></template>
      <div class="payment-options">
        <button
            class="payment-option"
            :disabled="$store.getters.selectedOrderTotal > $store.getters.points"
            :class="[{'selected': $store.getters.selectedOrder.mode == 'points'}, {'disabled': $store.getters.selectedOrderTotal > $store.getters.points}]"
            @click="$store.commit('orderModeChange', 'points')"
        >
          <div>Baly</div>
        </button>
        <button
            class="payment-option"
            :disabled="$store.getters.selectedOrderTotal > $store.getters.balance"
            :class="[{'selected': $store.getters.selectedOrder.mode == 'PLN'}, {'disabled': $store.getters.selectedOrderTotal > $store.getters.balance}]"
            @click="$store.commit('orderModeChange', 'PLN')"
        >
          <div>PLN</div>
        </button>
      </div>
      <div class="mx-3 text-right d-flex align-center">
        {{ $store.getters.selectedOrderTotal }} {{ $store.getters.selectedOrder.mode == 'points' ? "Baly" : "PLN" }}
      </div>
      <v-btn
          value="buy"
          :disabled="orderUnConfirmable"
          @click="$vuetify.goTo($refs.cart_main)"
          success
      >
        <v-icon color="success">mdi-cart</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-sheet rounded="lg" class="mt-3 py-1" ref="cart_main">
      <v-progress-circular
          v-if="!$store.getters.orders_loaded"
          indeterminate
          color="primary"
          class="mx-auto d-block my-3"
      ></v-progress-circular>
      <template v-if="$store.getters.orders_loaded">
    <!--    {{ $store.getters.selectedOrder }}-->
        <v-list class="pt-0" two-line v-if="$store.getters.selectedOrder">
          <v-list-item-group>
            <template v-for="(product, index) in $store.getters.selectedOrder.products">
              <v-list-item :key="index" inactive @click="selectOrderProduct(product.product_id)">
                <v-list-item-content>
                  <v-list-item-title>{{ product.title }}</v-list-item-title>

                  <v-list-item-subtitle
                      class="text--primary"
                  ></v-list-item-subtitle>

                  <v-list-item-subtitle>x{{ product.quantity }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>{{ product.quantity * product.price }} {{ $store.getters.selectedOrder.mode == 'points' ? "Baly" : "PLN" }}</v-list-item-action-text>

                  <v-btn icon text color="error" @click="removeProductFromOrder(product.product_id)">
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <div class="mb-3 mx-3 text-right d-flex align-center justify-end">
          <b class="mr-1">Nowość!</b> Dostawa od nas (0 {{ $store.getters.selectedOrder.mode == 'points' ? "Baly" : "PLN" }})
          <v-checkbox
              class="ml-3"
              :key="delivery"
              @change="setDelivery"
              :input-value="delivery"
          ></v-checkbox>
        </div>
        <div class="mb-3 mx-3 text-right">
          <b>Końcowa kwota:</b> {{ $store.getters.selectedOrderTotal }} {{ $store.getters.selectedOrder.mode == 'points' ? "Baly" : "PLN" }}
        </div>
        <div class="payment-options mb-3">
          <button
              class="payment-option"
              :disabled="$store.getters.selectedOrderTotal > $store.getters.points"
              :class="[{'selected': $store.getters.selectedOrder.mode == 'points'}, {'disabled': $store.getters.selectedOrderTotal > $store.getters.points}]"
              @click="$store.commit('orderModeChange', 'points')"
          >
            <div>Baly</div>
          </button>
          <button
              class="payment-option"
              :disabled="$store.getters.selectedOrderTotal > $store.getters.balance"
              :class="[{'selected': $store.getters.selectedOrder.mode == 'PLN'}, {'disabled': $store.getters.selectedOrderTotal > $store.getters.balance}]"
              @click="$store.commit('orderModeChange', 'PLN')"
          >
            <div>PLN</div>
          </button>
        </div>
        <v-btn
            class="success mb-n1"
            block
            :disabled="orderUnConfirmable"
            @click="confirmOrder"
        >{{ orderButtonText }}</v-btn>
  <!--          v-if="$store.getters.selectedOrder.status == 'confirmed'"-->
        <v-alert
            v-if="beforeNewYear"
            border="left"
            color="orange"
            outlined
            type="warning"
            text
            class="mt-3 mb-n1"
        >
          W Sylwestra 31 grudnia 2023 roku możliwa będzie dostawa pizzy naszym kurierem.
          Złóż zamówienie telefonicznie, zapłać Blikiem, a nasza pizza jak najszybciej trafi na Twój świąteczny stół.
          Dodatkowy koszt 10 zł
        </v-alert>
        <v-alert
            border="left"
            color="orange"
            outlined
            type="warning"
            text
            class="mt-3 mb-n1"
        >
          <b>Wreszcie 🎉</b>
          <br>Uruchomiliśmy własne dostawy kurierskie. Zamów pizzę z dostawą przez nas
        </v-alert>
      </template>
    </v-sheet>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  data: () => ({
    modal: false,
    date1: "",
    selectLocked: false,
    savingOrder: false,
  }),
  computed: {
    delivery() {
      return this.$store.getters.selectedOrder.delivery
    },
    beforeNewYear() {
      return new Date('01.01.2024') - new Date().getTime() > 0
    },
    orderUnConfirmable() {
      const order = this.$store.getters.selectedOrder
      if (!order) return true;
      if (order.status == "confirmed") return true;
      if (order.status == "new" && order.products.length == 0) return true;
      switch (order.mode) {
        case "points":
          if (this.$store.getters.selectedOrderTotal > this.$store.getters.points) return true;
          break;
        case "PLN":
          if (this.$store.getters.selectedOrderTotal > this.$store.getters.balance) return true;
          break;
      }
      if (this.savingOrder) return true

      return false
    },
    orderButtonText() {
      const order = this.$store.getters.selectedOrder
      if (!order) return "Ładowanie...";
      if (this.savingOrder) return "Zapisywanie..."
      if (order.status == "confirmed") return "Potwierdzony!"
      switch (order.mode) {
        case "points":
          if (this.$store.getters.selectedOrderTotal > this.$store.getters.points) return "Saldo niskie";
          break;
        case "PLN":
          if (this.$store.getters.selectedOrderTotal > this.$store.getters.balance) return "Saldo niskie";
          break;
      }
      if (order.status == "updated" && order.products.length == 0) return "Usuń zamówienie";
      if (order.status == "updated") return "Zaktualizuj zamówienie"
      return "Potwierdzić zamówienie"
    },
  },
  methods: {
    setDelivery(val) {
      this.$store.commit('setDelivery', val)
    },
    async confirmOrder() {
      if (this.savingOrder) return;
      this.savingOrder = true
      try {
        const result = await axios.post("/order", Object.assign(this.$store.getters.selectedOrder, {status: "confirmed"}))
        // if (result.status == 200) {
        //
        // }
        this.$store.dispatch("getOrders");
      } catch (e) {
        console.log(e)
      }
      this.savingOrder = false
    },
    removeProductFromOrder(productId) {
      this.$store.commit('removeProductFromOrder', productId)
    },
    selectOrderProduct(product_id) {
      if (this.selectLocked) return
      this.selectLocked = true
      const targetCategoryId = this.$store.getters.productCategoryById(product_id).id
      if (targetCategoryId != this.$route.params.categoryId) {
        this.$router.push(`/catalog/${targetCategoryId}`)
      }
      setTimeout(() => {
        this.$vuetify.goTo(`#product-${product_id}`, {})
        this.selectLocked = false
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.payment-options {
  display: flex;
  .payment-option {
    flex: 1;
    text-align: center;
    padding: 10px;
    overflow: hidden;
    position: relative;
    &:disabled {
      text-decoration: line-through;
      background: #0000002e;
    }
    & > div {
      position: relative;
    }
    &:before {
      content: "";
      position: absolute;
      background: #4caf50 !important;
      width: 100%;
      height: 100%;
      top: 0;
      transition: .5s;
    }
    &:first-child:before {
      left: 100%;
      //border-radius: 0 5px 5px 0;
    }
    &:last-child:before {
      left: -100%;
      //border-radius: 5px 0 0 5px;
    }
    &.selected {
      &:before {
        left: 0;
      }
    }
  }
}
</style>