import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

import auth from "./auth"
import catalog from "./catalog"
import order from "./order"
import app from "./app"
import points from "./points"

export default new Vuex.Store({
  state: {
  },
  getters: {
    paymentMode: () => {
      return process.env.VUE_APP_PAYMENT_MODE
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    auth,
    catalog,
    order,
    points
  },
  plugins: [createPersistedState({
    paths: [
      "auth.token",
      "auth.profileLoaded",
      "auth.verified"
    ]
  })],
})
