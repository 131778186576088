import {RouteConfig} from "vue-router";
import CatalogPage from "@/views/CatalogPage.vue"
import OrdersPage from "@/views/OrdersPage.vue"
import LoginPage from "@/views/LoginPage.vue"
import RegisterPage from "@/views/RegisterPage.vue"
import PaymentPage from "@/views/PaymentPage.vue"
import PointsPage from "@/views/PointsPage.vue"
import HelpPage from "@/views/HelpPage.vue"
import Home from "@/components/Home.vue"
import TokenRegister from "@/views/TokenRegister.vue"

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: Home,
        redirect: '/catalog'
    },
    {
        path: '/login',
        component: LoginPage,
        name: 'Login',
    },
    {
        path: '/register',
        component: RegisterPage,
        name: 'Register',
    },
    {
        path: '/catalog',
        component: CatalogPage,
        name: 'Catalog',
    },
    {
        path: '/catalog/:categoryId',
        component: CatalogPage,
        name: 'Category',
    },
    {
        path: '/orders',
        component: OrdersPage,
    },
    {
        path: '/pay',
        component: PaymentPage,
    },
    {
        path: '/points',
        component: PointsPage,
    },
    {
        path: '/help',
        component: HelpPage,
        name: 'Help',
    },
    {
        path: '/token/register/:id',
        component: TokenRegister,
        name: 'registerToken',
    }
]

export default routes