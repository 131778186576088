<template>
  <v-container>
    <v-alert v-if="state=='loading'" icon="mdi-loading mdi-spin" type="info">
      Aktualizujemy dane, prosimy o chwilę cierpliwości...
    </v-alert>
    <v-alert v-if="state=='success'" icon="mdi-party-popper" type="success">
      Zwycięstwo!!! Masz punkty!!! Przejdź do menu i wybierz coś pysznego, co sprawi ci przyjemność
    </v-alert>
    <v-alert v-if="state=='taken'" icon="mdi-alert" type="warning">
      Ten kod został już aktywowany! Zadzwoń do nas, Jeżeli uważasz, że tak nie jest
    </v-alert>
    <v-alert v-if="state=='takenByRequester'" icon="mdi-alert" type="warning">
      Ten kod został już przez Ciebie aktywowany!
    </v-alert>
    <v-alert v-if="state=='not found'" icon="mdi-alert" type="error">
      Nie udało się zeskanować kodu? Spróbuj ponownie lub przynieś ten kod do pizzerii. Dowiemy się gdzie leży problem
      :)
    </v-alert>
  </v-container>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  data: () => ({
    state: "loading"//loading, success, taken, not found
  }),
  async mounted() {
    this.$store.commit("addError", "Uruchomiliśmy własne dostawy kurierskie. Zamów pizzę z dostawą przez nas")
    if (!this.$store.getters.isAuthorized) {
      this.$store.commit("newPostAuthAction", {type: "applyToken", "id": this.$route.params.id});
      this.$router.push("/register");
    }

    try {
      let result = await axios.post(`/redeem-token/${this.$route.params.id}`)
      this.state = "success"
    } catch (err) {
      if (err.response.data.message == "Used") this.state = "taken"
      if (err.response.data.message == "UsedByRequester") this.state = "takenByRequester"
      if (err.response.data.message == "Not found") this.state = "not found"
    }
    this.$store.dispatch("loadProfile");
  }
}
</script>