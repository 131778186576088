import {Commit} from "vuex";
import axios from "@/plugins/axios";
import ProductsResponseInterface from "@/interfaces/ProductsResponseInterface";
import PostAuthAction from "@/interfaces/PostAuthAction"
import {AxiosError} from "axios";
import store from "@/store/index";
import router from "@/router";

interface StateInterface {
    id: string,
    balance: number,
    points: number,
    name: string,
    profileLoaded: boolean,
    token: string | null,
    verified: boolean,
    postAuthActions: PostAuthAction[]
}

export default {
    state: {
        id: "",
        balance: 0,
        points: 0,
        name: "Gość",
        profileLoaded: false,
        verified: false,
        token: localStorage.getItem('token') != null ? localStorage.getItem('token') : null,
        postAuthActions: []
    },
    actions: {
        login: async ({ state, commit }: {state: StateInterface, commit: Commit}, payload: {phone: string, password: string}) => {
            try {
                const result = await axios.post('/login', payload)
                // console.log(result)
                commit("saveToken", result.data.token)
                return true;
            } catch (e) {
                console.log(e)
                commit("addError", "Błąd autoryzacji")
                return false;
            }
        },
        register: async ({state, commit}: {state: StateInterface, commit: Commit}, payload: {name: string, address: string, phone: string, password: string}) => {
            try {
                const result = await axios.post('/register', payload)
                commit("saveToken", result.data.token)
                return true;
            } catch (e) {
                if (e instanceof AxiosError) {
                    // commit("addError", e?.response?.data?.message || "Error")
                    commit("addError", "Błąd autoryzacji")
                }
                return false;
            }
        },
        logout: async ({ state, commit }: {state: StateInterface, commit: Commit}) => {
            try {
                const result = await axios.post('/logout')
                commit("saveToken", null)
                commit("saveProfile", {
                    id: "",
                    name: "Gość",
                    balance: 0,
                    points: 0,
                    verified: false,
                })
                return true;
            } catch (e) {
                return false;
            }
        },
        loadProfile: async ({ state, commit }: {state: StateInterface, commit: Commit}, payload: {phone: string, password: string}) => {
            try {
                const result = await axios.get('/profile')
                commit("saveProfile", result.data);
                store.dispatch("executePostAuthActions");
            } catch (e) {
                commit("addError", "Failed to load profile data")
                commit("saveToken", null)
                return false
            }
            return true
        },
        executePostAuthActions: async ({state}: {state: StateInterface}) => {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const action = state.postAuthActions.shift()
                if (action === undefined) break;
                switch (action.type) {
                    case "applyToken":
                        router.push({name: "registerToken", params: {id: action.id}})
                        break;
                }
            }
            if (
                (router.currentRoute.name == "Login" || router.currentRoute.name == "Register") ||
                (router.currentRoute.name != "Catalog" && !store.getters.isAuthorized)
            )
            router.push('/catalog')
        },
    },
    mutations: {
        newPostAuthAction: (state: StateInterface, postAuthAction: PostAuthAction) => {
            state.postAuthActions.push(postAuthAction);
        },
        saveToken: (state: StateInterface, token: string | null) => {
            state.token = token
        },
        saveProfile: (
            state: StateInterface,
            {id, name, balance, points, verified}: {id: string, name: string, balance: number, points: number, verified: boolean}
        ) => {
            state.id = id;
            state.balance = balance;
            state.points = points;
            state.name = name;
            state.verified = verified;
            state.profileLoaded = true
        }
    },
    getters: {
        postAuthActions: (state: StateInterface) => {
            return state.postAuthActions
        },
        userId: (state: StateInterface) => {
            return state.id
        },
        name: (state: StateInterface) => {
            return state.name
        },
        balance: (state: StateInterface) => {
            return state.balance
        },
        points: (state: StateInterface) => {
            return state.points
        },
        isAuthorized: (state: StateInterface) => {
            return state.token && state.profileLoaded;
        },
        token: (state: StateInterface) => {
            return state.token;
        },
        verified: (state: StateInterface) => {
            return state.verified && state.token;
        }
    }
}

