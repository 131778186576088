
interface StateInterface {
    errors: string[],
    packageVersion: string
}

export default {
    state: {
        errors: [],
        packageVersion: process.env.PACKAGE_VERSION || '0'
    },
    mutations: {
        addError: (state: StateInterface, message: string) => {
            state.errors.push(message)
        },
        shiftError: (state: StateInterface) => {
            state.errors.shift()
        }
    },
    getters: {
        firstError: (state: StateInterface) => {
            return state.errors[0]
        },
        packageVersion: (state: StateInterface) => {
            return state.packageVersion;
        }
    }
}