<template>
  <v-container>
    <v-sheet
        elevation="1"
        class="px-3 py-3 rounded"
    >
      <p align="center">
        Zamów naszą pizzę w aplikacji, z której lubisz korzystać. (Bolt, Wolt, Uber, Glovo, Pyszne).<br>
        V środku pudełka po pizzy znajdziesz kod QR. Zeskanuj ten kod, a wszystko stanie się jasne.
      </p>
      <h1 align="center">
        Zamawiając pizzę 40 cm otrzymasz 4 punkty 😍
      </h1>
      <h1 align="center">
        Zamawiając pizzę 30 cm otrzymasz 3 punkty 🥰
      </h1>
      <h1 align="center">
        Zamawiając pizzę 20 cm otrzymasz 2 punkty 😊
      </h1>
      <p class="text-center mt-3">
        Aby kupić pizzę za punkty należy oddać tyle punktów ile pizza kosztuje w złotówkach ;)
      </p>
    </v-sheet>
    <v-sheet
        elevation="1"
        class="mt-3 px-3 py-3 rounded"
    >
      <v-list three-line>
        <template v-for="(item, index) in activated_points_list">
          <v-divider
              v-if="index > 0"
              :key="index"
              :inset="false"
          ></v-divider>

          <v-list-item
              :key="item.title"
          >
<!--            <v-list-item-avatar>-->
<!--              <v-icon>mdi-thumb-up</v-icon>-->
<!--              <v-icon>mdi-thumb-down</v-icon>-->
<!--            </v-list-item-avatar>-->

            <v-list-item-content>
              <v-list-item-title>Baly: {{ item.amount }}</v-list-item-title>
              <v-list-item-subtitle>Date: {{ formatDate(item.activated_at) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-sheet>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    dateFormat: null
  }),
  computed: {
    activated_points_list() {
      return this.$store.getters.activated_points_list;
    }
  },
  methods: {
    formatDate(_date) {
      let date = new Date(_date);
      return this.dateFormat.format(date);
    }
  },
  async mounted() {
    this.dateFormat = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    if (!this.$store.getters.points_loaded && this.$store.getters.isAuthorized)
      this.$store.dispatch("getPoints");
  }
}
</script>