<template>
  <v-container class="h-100">
    <div class="text-center my-5">
      <h1>Witamy serdecznie w pizzerii Mafioła!</h1>
      <h3>Mamy fioła na punkcie dobrego jedzenia</h3>
    </div>
    <v-row>
      <v-col cols="12" offset="0" md="4" offset-md="4">
        <v-card>
          <v-card-title>
            <div class="d-flex justify-space-between" style="width: 100%">
              <span>Zaloguj sie</span>
<!--              <v-spacer></v-spacer>-->
              <v-btn text color="primary" to="/register">
                Rejestr
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-text-field
                label="Numer telefonu"
                class="phone-number"
                hide-details
                v-model="phone"
                autocomplete="username"
            ></v-text-field>
            <v-text-field
                label="Hasło"
                hide-details
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                autocomplete="current-password"
                v-on:keyup.enter="login"
                class="password"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <span class="secondary--text">+48505389621 (Pomoc)</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="login" @click="login" :loading="loading">Zaloguj sie</v-btn>
          </v-card-actions>
        </v-card>
        <div class="mt-5 d-flex justify-center guest">
          <v-btn
              color="success"
              class="font-weight-bold"
              to="/catalog"
          >Gość</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    phone: "",
    password: "",
    showPassword: false,
    loading: false,
  }),
  methods: {
    async login() {
      this.loading = true
      let result = await this.$store.dispatch("login", {
        phone: this.phone,
        password: this.password
      })
      if (!result) {
        this.loading = false
        return;
      }
      result = await this.$store.dispatch("loadProfile");
      if (!result) {
        this.loading = false
        return;
      }
      this.loading = false
    }
  }
}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0 0 0px 1000px #1e1e1e inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>