import axios from "@/plugins/axios";
import {Commit, GetterTree, Module, MutationPayload} from "vuex";
import OrdersInterface from "@/interfaces/OrdersInterface";
import ProductInterface from "@/interfaces/ProductInterface";
import OrdersResponseInterface from "@/interfaces/OrdersResponseInterface";
import store from "@/store/index"
import OrderProductInterface from "@/interfaces/OrderProductInterface";
import {AxiosError} from "axios";

interface StateInterface {
    selected_order_date: string,
    orders: OrdersInterface[],
    orders_loaded: boolean,
}

export default {
    state: {
        selected_order_date: "",
        orders: [],
        orders_loaded: false,
    } as StateInterface,
    actions: {
        getOrders: async ({ commit }: {commit: Commit}) => {
            commit("setOrders", {data: []})
            commit("setOrdersLoaded", false)
            try {
                const response = await axios.get(`/orders`) as OrdersResponseInterface;
                commit("setOrders", response)
                if (!store.getters.selectedOrder) {
                    store.dispatch("createNewOrder");
                }
            } catch (e) {
                if (e instanceof AxiosError) {
                    commit("addError", e?.response?.data?.message || "Error")
                }
            }
        },
        createNewOrder: ({ state, commit }: {state: StateInterface, commit: Commit}) => {
            // if (state.selected_order_date == "") {
            //     console.error("UNABLE TO CREATE ORDER DATE ISN'T SELECTED YET")
            // }
            commit("createOrder", {
                id: null,
                status: "new",
                mode: "points",
                delivery: true,
                // delivery_day: state.selected_order_date,
                products: []
            } as OrdersInterface)
        },
        addProductToOrder: ({ state, commit }: {state: StateInterface, commit: Commit}, {productId}: {productId: string}) => {
            const product = store.getters.productById(productId);
            const orderIndex = state.orders.findIndex((order) => {
                return order.status == "confirmed" || order.status == "updated" || order.status == "new"
            })
            state.orders[orderIndex].products.push({
                product_id: product.id,
                image: product.image,
                title: product.product_description.title,
                quantity: 1,
                price: product.price
            } as OrderProductInterface)
            if (state.orders[orderIndex].status == "confirmed")
                state.orders[orderIndex].status = "updated"
        }
    },
    mutations: {
        setDelivery: (state: StateInterface, value: boolean) => {
            const orderIndex = state.orders.findIndex((order) => {return order.status == "confirmed" || order.status == "updated" || order.status == "new";})
            state.orders[orderIndex].delivery = value;
        },
        orderModeChange: (state: StateInterface, value: string) => {
            const orderIndex = state.orders.findIndex((order) => {return order.status == "confirmed" || order.status == "updated" || order.status == "new";})
            state.orders[orderIndex].mode = value;
            state.orders[orderIndex].status = "updated";
        },
        setOrdersLoaded: (state: StateInterface, value: boolean) => {
            state.orders_loaded = value
        },
        updateSelectedOrderDate: (state: StateInterface, date: string) => {
            state.selected_order_date = date
        },
        setOrders: (state: StateInterface, data: OrdersResponseInterface) => {
            state.orders = data.data
            state.orders_loaded = true
        },
        createOrder: (state: StateInterface, order: OrdersInterface ) => {
            state.orders.push(order)
        },
        increaseProductQuantity: (state: StateInterface, {productId}: {productId: string}) => {
            const orderIndex = state.orders.findIndex((order) => {return order.status == "confirmed" || order.status == "updated" || order.status == "new";})
            const orderProductIndex = state.orders[orderIndex].products.findIndex((product) => {return product.product_id == productId})
            state.orders[orderIndex].products[orderProductIndex].quantity++
            if (state.orders[orderIndex].status == "confirmed")
                state.orders[orderIndex].status = "updated"
        },
        decreaseProductQuantity: (state: StateInterface, {productId}: {productId: string}) => {
            const orderIndex = state.orders.findIndex((order) => {return order.status == "confirmed" || order.status == "updated" || order.status == "new";})
            const orderProductIndex = state.orders[orderIndex].products.findIndex((product) => {return product.product_id == productId})
            state.orders[orderIndex].products[orderProductIndex].quantity--
            if (!state.orders[orderIndex].products[orderProductIndex].quantity) {
                state.orders[orderIndex].products.splice(orderProductIndex, 1)
            }
            if (state.orders[orderIndex].status == "confirmed")
                state.orders[orderIndex].status = "updated"
        },
        removeProductFromOrder: (state: StateInterface, {productId}: {productId: string}) => {
            const orderIndex = state.orders.findIndex((order) => {return order.status == "confirmed" || order.status == "updated" || order.status == "new";})
            const orderProductIndex = state.orders[orderIndex].products.findIndex((product) => {return product.product_id == productId})
            state.orders[orderIndex].products.splice(orderProductIndex, 1)
        }
    },
    getters: {
        // selected_order_date: (state: StateInterface) => {
        //     return state.selected_order_date
        // },
        orders: (state: StateInterface) => {
            return state.orders
        },
        selectedOrder: (state: StateInterface) => {
            // if (state.selected_order_date == "") return null;
            const order = state.orders.find((order) => {
                return order.status == "confirmed" || order.status == "updated" || order.status == "new"
            })
            return order
        },
        selectedOrderProduct: (state: StateInterface) => (productId: string) => {
            const order = store.getters.selectedOrder;
            const orderProduct = order.products.find((product: OrderProductInterface) => {return product.product_id == productId});
            // if (!orderProduct) {
            //     store.dispatch("addProductToOrder", {productId: productId})
            // }
            return orderProduct
        },
        selectedOrderTotal: (state: StateInterface) => {
            const order = store.getters.selectedOrder as OrdersInterface | null;
            if (!order) return 0
            return order.products.reduce((acc, product) => {
                return acc + (product.price * product.quantity)
            }, 0)
        },
        orderTotalByDate: (state: StateInterface) => (date: string) => {
            const order = state.orders.find((order) => {return order.status == "confirmed" || order.status == "updated" || order.status == "new";})
            if (!order) return 0
            return order.products.reduce((acc, product) => {
                return acc + (product.price * product.quantity)
            }, 0)
        },
        orders_loaded: (state: StateInterface) => {
            return state.orders_loaded
        },
    }
}