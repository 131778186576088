<template>
  <v-container>
    <template v-if="$store.getters.isAuthorized && $store.getters.verified && $store.getters.paymentMode !== 'false'">
      <h1 class="text-center">Doładowanie konta:</h1>
      <v-alert
          dense
          text
          type="success"
          v-if="paymentIntent && paymentIntent.status == 'succeeded' && !loading"
      >
        Płatność {{ paymentIntent.amount / 100 }} zł została dodana do Twojego salda
      </v-alert>
      <div class="d-flex flex-wrap justify-center">
        <v-sheet
            elevation="1"
            class="px-3 py-1 rounded"
        >
          <v-btn
              class="ma-2"
              v-for="(tier, color) in paymentTiers"
              :outlined="tier != selected"
              :color="color"
              @click="pay(tier)"
              :key="color"
              :loading="loading"
          >
          {{ tier }} zł
          </v-btn>
        </v-sheet>
      </div>
      <div ref="stripeInjection" class="mt-3"></div>
      <div v-if="selected && !loading" class="d-flex justify-end mt-5">
        <v-btn @click="confirmPay" color="success" class="d-inline-block">Pay</v-btn>
      </div>
    </template>
    <template v-if="$store.getters.paymentMode === 'false'">
      <v-sheet
          elevation="1"
          class="px-3 py-1 rounded"

      >
        <h1 align="center">
        Przepraszamy. Płatność kartą jest chwilowo niedostępna
        </h1>
      </v-sheet>
    </template>
    <template v-if="$store.getters.isAuthorized && !$store.getters.verified">
      <h1>Jak zweryfikować?</h1>
      <ul>
        <li>Zadzwoń +48505389621 i złóż pierwsze zamówienie</li>
        <li>Z niezweryfikowanymi użytkownikami kontaktujemy się codziennie od PON-PT od 15:00 do 20:00</li>
      </ul>
    </template>
    <v-expansion-panels class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Zwrot środków
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Zwrot środków, który pozostały na Twoim koncie lub zrobione nieprawidłowe zamówienie, można rozwiązać, po prostu dzwoniąc do nas
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
import axios from "@/plugins/axios"
import {AxiosError} from "axios";

export default {
  data: () => ({
    stripe: null,
    elements: null,
    loading: false,
    paymentTiers: {
      "success": 20,
      "#6e9eeb": 40,
      "#9700ff": 80,
      "#e79138": 100,
      "error": 200,
    },
    selected: "",
    paymentIntent: null
  }),
  methods: {
    async pay(amount) {
      if (this.loading) return
      this.loading=true
      this.selected = amount
      let result = await axios.post("/pay", {amount: amount})
      const options = {
        clientSecret: result.data.client_secret,
        // Fully customizable with appearance API.
        appearance: {
          theme: 'night',
          labels: 'floating'
        },
      };
      this.elements = this.stripe.elements(options);
      const paymentElement = this.elements.create('payment');
      paymentElement.mount(this.$refs.stripeInjection);
      this.loading=false
    },
    async confirmPay() {
      this.loading = true;
      const {error} = await this.stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements: this.elements,
        confirmParams: {
          return_url: `${process.env.VUE_APP_BASE_URL}/pay`,
        },
      });
      this.$store.commit("addError", "Error")
      this.loading = false
    }
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE);
    if (this.$route.query.payment_intent_client_secret) {
      this.loading = true;
      this.stripe.retrievePaymentIntent(this.$route.query.payment_intent_client_secret).then(async ({paymentIntent}) => {
        this.paymentIntent = paymentIntent
        try {
          const data = await axios.post("/balance/add", {
            pi_id: paymentIntent.id,
            client_secret: paymentIntent.client_secret
          });
          this.$store.dispatch("loadProfile")
        } catch (e) {
          this.loading = false;
          if (e instanceof AxiosError) {
            this.$store.commit("addError", e?.response?.data?.message || "Error")
          }
        }
        this.$router.replace({'query': null});
        this.loading = false;
      });
    }
  }
}
</script>